import React, {FC, InputHTMLAttributes, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';

import {IFormFieldProps} from '../../IFormFieldProps';
import Element from '../../../../domain/Element/Element';
import FieldErrorMessage from '../messages/FieldErrorMessage';
import FormGateway from '../../../../gateway/Form/FormGateway';
import ConvertNumberToWordsUseCase from '../../../../domain/Convert/UseCase/ConvertNumberToWordsUseCase';
import NumberToWordConverter from '../../../util/NumberToWordConverter';
import ValidationSchema from '../../../../validation/ValidationSchema';

import {Popover} from "react-tiny-popover";
import CopyCustom from "../../svg/CopyCustom";

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id?: string
  classes: string,
  value?: string,
  defaultValue?: string
  reference: string,
  timestamp: number|null,
  element: Element,
  register: any
}

interface CustomComponentProps extends React.ComponentPropsWithoutRef<'div'> {
    onMouseEnter(): void;

    onMouseLeave(): void;
}

const CustomComponent = React.forwardRef<HTMLDivElement, CustomComponentProps>((props, ref) => (
    <div ref={ref} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        {props.children}
    </div>
));

const InputConvertNumberToWord: FC<IProps> = ({id, classes, label, value, defaultValue, register, error, required, reference, timestamp, element, ...rest}) => {
  const { t } = useTranslation()

  const [convertedString, setConvertedString] = useState<string>('')
  const [schema] = useState<Record<string, unknown>>(new ValidationSchema().create(element, (required === true)))
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [numberPartValue, setNumberPartValue] = useState<string|undefined>(value ?? defaultValue ?? '')

  useEffect(() => {
    setConvertedString(new ConvertNumberToWordsUseCase(new FormGateway(), new NumberToWordConverter()).execute(reference))
  }, [timestamp, reference]);

  const handleChangeInputValue = (e) => {
      setNumberPartValue(e.target.value);
  }

  return (
    <div className={`${classes}  ${rest.readonly && "readonly-element"}`}>

      <div className={`form-floating ${error && 'error'} `} title={rest.help}>

        <input className="form-control" value={numberPartValue} defaultValue={defaultValue} readOnly={rest.readonly} {...rest}
               ref={register({
                 "required": schema.required,
                 "validate": v => v.toLocaleUpperCase() === convertedString.toLocaleUpperCase() || t('element.error.different-to-expected')
               })}
               onChange={handleChangeInputValue}
               required={required}
               autoComplete="off"
               name={id} />

        <label htmlFor={id}>{label}</label>

        {'' !== convertedString &&
          <div className="flex-container middle-xs no-compensation-xs">
            <span className="u-mxs u-pointer" onClick={() => setNumberPartValue(convertedString)}>
                <Popover
                    isOpen={isPopoverOpen}
                    positions={['bottom']}
                    align={'center'}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    content={<div className="box-helpers">Cliquez ici pour <b>coller</b> cette valeur dans le champ <b>{label}</b>.</div>}>
                    <CustomComponent onMouseEnter={() => setIsPopoverOpen(true)}
                                     onMouseLeave={() => setIsPopoverOpen(false)}>
                        <CopyCustom />
                    </CustomComponent>
                </Popover>
            </span>
            <span className="help">{convertedString}</span>
            <span className="u-help-input-convert-number">

            </span>
          </div>
        }

        {error?.message && <FieldErrorMessage message={error.message} />}

      </div>
    </div>
  )
}

export default InputConvertNumberToWord;
