import * as React from 'react'
import {FunctionComponent, useState} from 'react'

import GetUrl from '../../../../useCase/Docusign/GetUrl'
import FetchDocusignGateway from '../../../../gateway/Docusign/FetchDocusignGateway'
import Loader from '../../loader/Loader'
import Button from '../../button/Button';
import storage from '../../../util/storage';
import Create from '../../../../useCase/Prospect/Create';
import FetchProspectGateway from '../../../../gateway/Prospect/FetchProspectGateway';
import Authorize from '../../../../useCase/Client/Authorize';
import FetchClientGateway from '../../../../gateway/Client/FetchClientGateway';
import FieldErrorMessage from '../messages/FieldErrorMessage';
import {useTranslation} from 'react-i18next';
import Notification from '../../notification/Notification';
import {ENotificationType} from '../../notification/ENotificationType';
import Client from '../../../../domain/Client/Client';
import {Folder} from '../../../../domain/Folder/Folder';

const ButtonCreateProspect: FunctionComponent = () => {
  const { t } = useTranslation()
  const clientId = storage.getClientId()
  const dossierId = storage.getFolderId()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isVisibleCreateProspect, setVisibleCreateProspect] = useState<boolean>(clientId === null)
  const [isVisibleAuthorizeClient, setVisibleAuthorizeClient] = useState<boolean>(clientId !== null)
  const [success, setSuccess] = useState<string|null>(null)
  const [errors, setErrors] = useState<Error[]|null>(null)

  const handleClick = (action: string) => {
    if (action === 'create' && dossierId !== null) {
      setLoading(true)
      new Create(new FetchProspectGateway()).execute(dossierId).then(response => {
        if (response instanceof Client) {
          setVisibleCreateProspect(false)

          storage.setClientId(response.id)

          new Authorize(new FetchClientGateway()).execute(dossierId, response.id).then(responseAuthorize => {
            if (responseAuthorize instanceof Folder) {
              storage.setAuthorizedClients(responseAuthorize.authorizedClients)
              setLoading(false)
              setSuccess('common.success-prospect')
            }
          })
        } else {
          setErrors(response)
          setLoading(false)
        }
      })

      return false
    }

    if (clientId !== null && dossierId !== null) {
      setLoading(true)
      new Authorize(new FetchClientGateway()).execute(dossierId, clientId).then(response => {
        if (response instanceof Folder) {
          setVisibleAuthorizeClient(false)
          storage.setAuthorizedClients(response.authorizedClients)
          setSuccess('common.success-client')
        } else {
          setErrors(response)
        }

        setLoading(false)
      }).catch(() => {
        setVisibleAuthorizeClient(true)
      })

    }
  }
  const readOnlyMode = 'back_office_user' == localStorage.getItem('role');
  return (
    <div className="col-md-12">
      {isVisibleCreateProspect && !isLoading &&
      <div className="flex-container middle-xs center-xs">
        <Button classes={`button button-primary--outline button--medium button--width-fit ${null !== errors && 'button-error--outline'}`}
                onClick={() => handleClick('create')}
                label={t('common.create-prospect')}
                disabled={readOnlyMode} />
      </div>
      }
      {isVisibleAuthorizeClient && !isLoading &&
      <div className="flex-container middle-xs center-xs">
        <Button classes={`button button-primary--outline button--medium button--width-fit ${null !== errors && 'button-error--outline'}`}
                onClick={() => handleClick('authorize')}
                label={t('common.authorize-client')}
                disabled={readOnlyMode} /> <br/>
      </div>
      }
      {(isLoading &&
        <Loader />
      )}

      {(success !== null &&
        <Notification label={t(success)} type={ENotificationType.success} />
      )}

      {null !== errors &&
        <div className="u-txt-center">
          {errors.map((error, index) => {
            return (
              <><FieldErrorMessage message={t(`api.error.${error.message}`)} key={index} /> <br/></>
            )
          })}
        </div>
      }
    </div>
  )
}

export default ButtonCreateProspect
