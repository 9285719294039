import FormGatewayInterface from '../../Form/FormGatewayInterface';
import OperatorFactory from '../OperatorFactory';
import LogicalOperator from '../LogicalOperator';
import {RuleTypeDefinition} from '../RuleTypeDefinition';
import ICondition from "../ICondition";

export default class ValidateConditionUseCase
{
  public formGatewayInterface

  constructor(FormGatewayInterface: FormGatewayInterface) {
    this.formGatewayInterface = FormGatewayInterface
  }

  execute(condition: ICondition): boolean {
    if (null === condition) {
      return true
    }

    const datas = this.formGatewayInterface.getCurrentValueForFormId()
    return ValidateConditionUseCase.validate(condition, datas)
  }

  private static validate(condition, datas: [values: Record<string, unknown>, direct: boolean, dossier_state: string]): boolean {
    const conditionArray : boolean[] = [];

    (condition.rules).map(rule => {
      switch (rule.type) {
        case RuleTypeDefinition.ELEMENT:
          conditionArray.push((OperatorFactory.instance(rule.operator)).validate(rule.value, datas['values'][rule.input_id]))
          break
        case RuleTypeDefinition.FOLDER:
          conditionArray.push(datas['direct'] === rule.direct)
          break
        case RuleTypeDefinition.STATE:
          conditionArray.push((OperatorFactory.instance(rule.operator)).validate(datas['dossier_state'], rule.state))
          break
        default:
          conditionArray.push((OperatorFactory.instance(rule.operator)).validate(rule.value, datas['values'][rule.input_id]))
          break
      }
    })

    return LogicalOperator.validate(conditionArray, condition.logical_operator)
  }
}
