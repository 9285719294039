import FormGatewayInterface from './FormGatewayInterface';
import FolderGatewayInterface from '../Folder/FolderGatewayInterface';

export default class PostUseCase
{
    public formGateway;
    public folderGateway;

    constructor(FormGatewayInterface: FormGatewayInterface, FolderGateway: FolderGatewayInterface) {
        this.formGateway = FormGatewayInterface;
        this.folderGateway = FolderGateway;
    }

    execute(data: Array<string>, blockId: string): Promise<unknown> {
        return this.folderGateway.updateFolder(data, blockId).then((response) => {
            this.formGateway.updateState(response.status)
            return this.formGateway.save(data)
        })
    }
}
