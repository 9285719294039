import DocumentGatewayInterface from '../../domain/Document/DocumentGatewayInterface';
import Caller from "../../domain/Api/Caller";
import envVariable from "../../presentation/util/envVariable";
import storage from '../../presentation/util/storage';
import Receipt from '../../domain/Receipt/Receipt';

export default class FetchDocumentGateway implements DocumentGatewayInterface {

  send(blockId: string, documents: Document[]): Promise<Receipt[]|null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/receipts`, {
      submittingBlockId: blockId,
      receipts: documents
    })
      .then(data => {
        if (!data) {
          return null
        }

        const receipts: Receipt[] = []
        data.dossier.receipts.map(receipt => {
          receipts.push(new Receipt(receipt.receiptId, receipt.typeId, receipt.elementId, receipt.title, receipt.resource))
        })

        return receipts
      }).catch(() => {
        return null
      })
  }
}
