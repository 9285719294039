/* eslint-disable  @typescript-eslint/no-explicit-any */

import {EElementType} from './EElementType';
import ElementPreview from './ElementPreview';
import Element from './Element';
import ValidateConditionUseCase from '../Condition/UseCase/ValidateConditionUseCase';
import FormGateway from '../../gateway/Form/FormGateway';
import {isDisabledCondition, isVisibleCondition} from '../../presentation/util/validateCondition';
import PercentageElementPreview from "./Percentage/PercentageElementPreview";
import OrderElementPreview from "./Order/OrderElementPreview";

export default class ElementPreviewPresenter {

    /**
     * Returns Preview Element Object or null
     * @param element
     * @param elementId
     * @param submittedData
     * @param elements
     */
    execute(
        element: Element,
        elementId: string | null | undefined,
        submittedData: Record<string, any>,
        elements: Element[] | null
    ): ElementPreview | null {

        let valueEntered = undefined != elementId ? String(submittedData[elementId]) : null;

        const fieldType: EElementType = <EElementType>element.type;
        const attributes = element.attributes;
        const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())

        const validationState = (undefined !== element.condition)
            ? validateConditionUseCase.execute(element.condition)
            : true

        if (!valueEntered
            || isDisabledCondition(element, validationState)
            || !isVisibleCondition(element, validationState)
            || '' === (attributes?.presentation ?? '')
        ) {
            return null
        }

        const presentationString = ('empty' !== attributes?.presentation) ? `${attributes?.presentation}` : '';

        const selectedOption = element.attributes?.options?.find(option => option.value == valueEntered) ?? null

        switch (fieldType) {
            case EElementType.INPUT_TEXT:
                valueEntered = `<span>${presentationString}</span>
                                <span class="u-txt-size-xl u-mxs">&#x27A0;</span>
                                <span>
                                    <strong><u class="u-txt-color-ink-1">${valueEntered}</u></u></strong>
                                </span>`;
                break;
            case EElementType.INPUT_INTEGER:
            case EElementType.INPUT_FLOAT:
            case EElementType.INPUT_IBAN:
            case EElementType.INPUT_IBAN_CODE:
            case EElementType.INPUT_BIC_CODE:
            case EElementType.CALCULATE:
            case EElementType.INPUT_CONVERT_NUMBER_TO_WORD:
            case EElementType.INPUT_RANGE:
            case EElementType.INPUT_PRODUCT_SHARES_NUMBER:
                valueEntered = `<span>${presentationString}</span>
                                <span><strong><u class="u-txt-color-ink-1">${valueEntered}</u></strong></span>`;
                break
            case EElementType.INPUT_DATE:
            case EElementType.INPUT_BIRTHDATE:
                valueEntered = `<span>${presentationString}</span>
                                <span class="u-txt-size-l u-mxs">&#x1F4C6;</span>
                                <span><strong><u class="u-txt-color-ink-1">${valueEntered}</u></strong></span>`;
                break
            case EElementType.INPUT_TEL:
                valueEntered = `<span class="u-txt-size-l u-mrs"><strong>&#x2706;</strong></span>
                                <span><strong><u class="u-txt-color-ink-1">${valueEntered}</u></strong></span>
                                <span class="u-mls">(${presentationString})</span>`;
                break;
            case EElementType.INPUT_MAIL:
                valueEntered = `<span class="u-txt-size-l u-mrs"><strong>&#x2709;</strong></span>
                                <span><strong><u class="u-txt-color-ink-1">${valueEntered}</u></strong></span>
                                <span class="u-mls">(${presentationString})</span>`;
                break;
            case EElementType.INPUT_PERCENTAGE_INTEGER:
                // eslint-disable-next-line no-case-declarations
                const percentageElementPresentation = PercentageElementPreview.presentElement(
                    attributes?.childElementId ?? '',
                    submittedData,
                    valueEntered,
                    presentationString
                )
                if (null === percentageElementPresentation) {
                    return null;
                }
                valueEntered = percentageElementPresentation
                break;
            case EElementType.INPUT_PERCENTAGE_RANGE:
                return null;
            case EElementType.GROUP_PERCENTAGE_INPUT_RANGE:
                valueEntered = PercentageElementPreview.presentGroup(element, submittedData, elements)
                break;
            case EElementType.INPUT_ORDERED_INTEGER:
            case EElementType.SELECT_ORDERED_INTEGER:
                return null
            case EElementType.GROUP_ORDERED_INTEGERS:
                valueEntered = OrderElementPreview.presentGroup(element, submittedData, elements)
                break
            case EElementType.CHOICE_AUTOCOMPLETE:
            case EElementType.INPUT_RADIO:
            case EElementType.CHOICE:
            case EElementType.SELECT:
            case EElementType.INPUT_RADIO_SIMPLE:
                if (null === selectedOption) {
                    return null
                }
                valueEntered = `<strong><u class="u-txt-color-ink-1">${selectedOption.presentation}</u></strong>`;
                valueEntered += `&nbsp;(${presentationString})`;
                break
            case EElementType.TOGGLE:
                if (null === selectedOption) {
                    return null
                }
                // eslint-disable-next-line no-case-declarations
                const toggleIcon = 'true' === selectedOption.value ? '&#9746;' : '&#9744;'
                valueEntered = `<span class="u-txt-color-ink-1 u-mrs">${toggleIcon}</span>
                                <span>
                                    <strong><u class="u-txt-color-ink-1">${selectedOption.presentation}</u></strong>
                                </span>
                                <span class="u-mxs">(${presentationString})</span>`;
                break
            case EElementType.INPUT_CHECKBOX:
                if (null === selectedOption) {
                    return null
                }
                // eslint-disable-next-line no-case-declarations
                const checkBoxIcon = 'true' === selectedOption.value ? '&#9745;' : '&#9744;'
                valueEntered = `<span class="u-txt-color-ink-1 u-mrs">${checkBoxIcon}</span>
                                <span>
                                    <strong><u class="u-txt-color-ink-1">${selectedOption.presentation}</u></strong>
                                </span>
                                <span class="u-mxs">(${presentationString})</span>`;
                break
            case EElementType.INPUT_SORT:
                if (submittedData[element.name]) {
                    element.attributes?.options?.map((option) => {
                        if (option.value === submittedData[element.name][0] && '' !== attributes?.presentation) {
                            return new ElementPreview(`${option.presentation} ...`, element.name)
                        }
                    })
                }
                return null
            case EElementType.INPUT_FLOAT_CURRENCY_AMOUNT:
                valueEntered = parseFloat(valueEntered).toFixed(2)
                valueEntered = `<span><strong><u class="u-txt-color-ink-1">${valueEntered}&nbsp;€</u></strong></span>
                                <span class="u-txt-size-xl u-mxs">&#9754;</span>
                                <span>${presentationString}</span>`
                break
            case EElementType.PRESENTATION:
                valueEntered = `<span>${presentationString}</span>
                                <span class="u-txt-size-xl u-mxs">&#9755;</span>
                                <span><strong><u class="u-txt-color-ink-1">${valueEntered}</u></strong></span>`;
                break
            default:
                return null
        }

        return new ElementPreview(valueEntered, element.name)

    }

}
