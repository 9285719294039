import {FC, FormEvent, InputHTMLAttributes, useState} from 'react'
import {Control, Controller} from 'react-hook-form'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import SaveUseCase from '../../../../domain/Form/SaveUseCase'
import Element from '../../../../domain/Element/Element'
import FormGateway from '../../../../gateway/Form/FormGateway'

import {IFormFieldProps} from '../../IFormFieldProps'

import FieldErrorMessage from '../messages/FieldErrorMessage'
import getElementsIdToRender from '../../../util/getElementsIdToRender'
import {updateElementTimestamp} from '../../../../store/element/elements'
import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {FieldValues} from "react-hook-form/dist/types";

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
    id?: string;
    classes: string;
    value?: string;
    defaultValue?: string;
    onChange?: (e: FormEvent<HTMLInputElement>) => void;
    clearErrors: (id: string | undefined) => void;
    control?: Control<FieldValues>;
    schema?:  Record<string, unknown>;
}

const Phone: FC<IProps> = ({
                               id,
                               classes,
                               defaultValue,
                               control,
                               schema,
                               clearErrors,
                               error,
                               ...rest
                           }) => {
    const dispatch = useAppDispatch()

    const [valueFormatted, setValueFormatted] = useState(defaultValue)

    const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
    const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
    const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
    const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
    const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
    const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

    function handleChange(value) {
        setValueFormatted(value)
        clearErrors(id)

        if (undefined !== id) {
            const data = []
            data[id] = value

            const saveUseCase = new SaveUseCase(new FormGateway())
            saveUseCase.execute(data).then(() => {
                const elementsIdToRender: string[] = getElementsIdToRender(elementsWithCondition, id, elementsWithCalcule, elementsWithReference)
                elementsIdToRender.map(elementIdToRender => dispatch(updateElementTimestamp({'id': elementIdToRender})))
            })
        }
    }

    if (undefined !== defaultValue && undefined !== id) {
        const response = new FormGateway().getCurrentValueForFormId()

        if (undefined === response.values[id]) {
            const data = []
            data[id] = defaultValue

            const saveUseCase = new SaveUseCase(new FormGateway())
            saveUseCase.execute(data).then(r => r)
        }
    }

    return (
        <div className={`${classes}  ${rest.readonly && "readonly-element"}`}>
            <div className={`form-floating ${error && 'error'} `} title={rest.help}>
                <Controller
                    name={id || ''}
                    control={control}
                    rules={schema}
                    defaultValue={valueFormatted}
                    render={({onChange}) => (
                        <PhoneInput
                            placeholder={rest['placeholder']}
                            value={valueFormatted}
                            onChange={(val) => {
                                onChange(val)
                                handleChange(val)
                            }}
                            name={id}
                        />
                    )}
                />
                {error?.message && <FieldErrorMessage message={error.message}/>}
            </div>
        </div>
    )
}

export default Phone
